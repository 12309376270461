import { DateTimeConstants } from './../../shared/constants/dateTime.constants';
import { ToastrService } from 'ngx-toastr';
import { TrainingRegistrationDialogData } from './../../shared/models/trainingRegistrationDialogData';
import { TrainingRegistrationDialogComponent } from './../../shared/components/training-registration-dialog/training-registration-dialog.component';
import { TrainingDay } from './../../shared/models/traningDay';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TrainingTypeConstants } from 'app/shared/constants/training-types.constants';
import { nextSunday } from 'date-fns'

@Component({
  selector: 'app-g-sport-page',
  templateUrl: './g-sport-page.component.html',
  styleUrls: ['./g-sport-page.component.scss']
})
export class GSportPageComponent implements OnInit {

  dateTimeConstants = DateTimeConstants;
  // toggle deze boolean om de trainingen te tonen of niet
  openForTrainings = false;
  trainingDays: TrainingDay[] = [];

  constructor(public dialog: MatDialog, private _toastrService: ToastrService) { }

  ngOnInit(): void {
    this.getGSportTrainingDays();
  }

  getGSportTrainingDays() {
    let count = 0;
    let sundayToCountFrom: Date = new Date();

    do {
      let sunday = nextSunday(sundayToCountFrom);
      sundayToCountFrom = sunday;
      this.trainingDays.push(
        {
          dateTimeFrom: new Date(sunday.getFullYear(), sunday.getMonth(), sunday.getDate(), 13, 0),
          dateTimeUntil: new Date(sunday.getFullYear(), sunday.getMonth(), sunday.getDate(), 14, 30)
        });
      count++;
    } while (count < 5)
  }

  openRegisterDialog(trainingDay: TrainingDay) {
    if (trainingDay) {
      let data: TrainingRegistrationDialogData = {
        date: trainingDay.dateTimeFrom,
        type: TrainingTypeConstants.GSPORT
      };

      this.dialog.open(TrainingRegistrationDialogComponent, {
        width: '500px',
        data
      });
    }
    else {
      this._toastrService.error("Er is iets misgegaan bij het inschrijven. Probeer het nogmaals.");
    }
  }
}

import { EmailConstants } from './../../constants/email.constants';
import { DateTimeConstants } from './../../constants/dateTime.constants';
import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TrainingTypeConstants } from 'app/shared/constants/training-types.constants';
import { TrainingRegistrationDialogData } from 'app/shared/models/trainingRegistrationDialogData';
import { RegistrationsService } from 'app/shared/services/registrations.service';
import { ToastrService } from 'ngx-toastr';
import { last } from 'rxjs/operators';

@Component({
  selector: 'app-training-registration-dialog',
  templateUrl: './training-registration-dialog.component.html',
  styleUrls: ['./training-registration-dialog.component.scss']
})
export class TrainingRegistrationDialogComponent implements OnInit {
  registrationForm: FormGroup;

  // Seperate focusses needed for icons in formfield
  focusFistname;
  focusLastname;
  focusAdres;
  focusEmail;
  focusPhone;
  focusMutualiteit;

  get isValidForm() {
    return this.registrationForm.valid;
  }

  get firstName() { return this.registrationForm.get('firstName'); }
  get lastName() { return this.registrationForm.get('lastName'); }
  get phoneNr() { return this.registrationForm.get('phoneNr'); }
  get email() { return this.registrationForm.get('email'); }
  get adress() { return this.registrationForm.get('adress'); }
  get mutualiteit() { return this.registrationForm.get('mutualiteit'); }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TrainingRegistrationDialogData,
    public dialogRef: MatDialogRef<TrainingRegistrationDialogComponent>,
    private _formBuilder: FormBuilder,
    private _registrationsService: RegistrationsService,
    private _toastrService: ToastrService,
    private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.buildForm();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  buildForm() {
    this.registrationForm = this._formBuilder.group({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      phoneNr: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')]),
      adress: new FormControl('', [Validators.required]),
      mutualiteit: new FormControl('', [Validators.required]),
    });
  }

  sendRegistration() {
    if (this.isValidForm) {
      const subject = this.GetSubject(this.data.date, this.data.type);
      const message = this.GetMessage(
        this.firstName.value,
        this.lastName.value,
        this.phoneNr.value,
        this.adress.value,
        this.mutualiteit.value,
        this.data.type,
        this.data.date);

      const emailTo = this.getEmailTo(this.data.type);

      const emailFrom = this.email.value;

      this._registrationsService.sendEmail(subject, message, emailTo, emailFrom).subscribe(() => {
        this._toastrService.success('Uw inschrijving werd verstuurd.');
        this.dialogRef.close();
      },
        () => {
          this._toastrService.error('Er is iets misgegaan');
          this.dialogRef.close();
        });
    }
  }

  private GetSubject(trainingDate: Date, trainingType: TrainingTypeConstants): string {
    if (trainingType == TrainingTypeConstants.PRIVATE_TRAINING) {
      return this.CreatePrivateTrainingSubject(trainingType);
    } else {
      return this.CreateGSportSubject(trainingDate, trainingType);
    }
  }

  private CreateGSportSubject(trainingDate: Date, trainingType: TrainingTypeConstants): string {
    return "Inschrijving voor " + trainingType + " op " + this.datePipe.transform(trainingDate, DateTimeConstants.DATE_TIME);
  }

  private CreatePrivateTrainingSubject(trainingType: TrainingTypeConstants): string {
    return "Info aanvraag voor " + trainingType;
  }

  private GetMessage(firstname: string, lastname: string, phone: string, adress: string, mutualiteit: string, trainingType: TrainingTypeConstants, trainingDate: Date): string {
    if (trainingType == TrainingTypeConstants.GSPORT) return this.GetGSportMessage(firstname, lastname, phone, adress, mutualiteit, trainingType, trainingDate);
    else return this.GetPrivateTrainingMessage(firstname, lastname, phone, adress, mutualiteit, trainingType);

  }

  private GetGSportMessage(firstname: string, lastname: string, phone: string, adress: string, mutualiteit: string, trainingType: TrainingTypeConstants, trainingDate: Date): string {
    return "<html><body>" +
      "<p>Inschrijving van " + firstname + " " + lastname + " voor " + trainingType + "</p>" +
      "<p>Training van : " + this.datePipe.transform(trainingDate, DateTimeConstants.DATE_TIME) + "</p>" +
      "<p>Persoonsgegevens: </p>" +
      "<p> Voornaam: " + firstname + "</p>" +
      "<p> Achternaam: " + lastname + "</p>" +
      "<p> Gsm-nummer: " + phone + "</p>" +
      "<p> Adres: " + adress + "</p>" +
      "<p> Mutualiteit: " + mutualiteit + "</p>" +
      "</body></html>";
  }

  private GetPrivateTrainingMessage(firstname: string, lastname: string, phone: string, adress: string, mutualiteit: string, trainingType: TrainingTypeConstants): string {
    return "<html><body>" +
      "<p>Aanvraag voor " + trainingType + " van  " + firstname + " " + lastname + "</p>" +
      "<p>Persoonsgegevens: </p>" +
      "<p> Voornaam: " + firstname + "</p>" +
      "<p> Achternaam: " + lastname + "</p>" +
      "<p> Gsm-nummer: " + phone + "</p>" +
      "<p> Adres: " + adress + "</p>" +
      "<p> Mutualiteit: " + mutualiteit + "</p>" +
      "</body></html>";
  }

  private getEmailTo(trainingType: TrainingTypeConstants) {
    if (trainingType == TrainingTypeConstants.GSPORT) return EmailConstants.GSPORT;
    else return EmailConstants.PRIVATE_TRAINING;
  }
}

import { DateTimeConstants } from './../../shared/constants/dateTime.constants';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TrainingRegistrationDialogComponent } from 'app/shared/components/training-registration-dialog/training-registration-dialog.component';
import { TrainingTypeConstants } from 'app/shared/constants/training-types.constants';
import { TrainingRegistrationDialogData } from 'app/shared/models/trainingRegistrationDialogData';
import { TrainingDay } from 'app/shared/models/traningDay';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-private-trainings-page',
  templateUrl: './private-trainings-page.component.html',
  styleUrls: ['./private-trainings-page.component.scss']
})
export class PrivateTrainingsPageComponent implements OnInit {

  dateTimeConstants = DateTimeConstants;

  constructor(public dialog: MatDialog, private _toastrService: ToastrService) { }

  ngOnInit(): void {
  }

  openDialog() {
    let data: TrainingRegistrationDialogData = {
      type: TrainingTypeConstants.PRIVATE_TRAINING
    };

    this.dialog.open(TrainingRegistrationDialogComponent, {
      width: '500px',
      data
    });
  }

}

import { PathLocationStrategy } from '@angular/common';
// General modules
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { RouterModule } from "@angular/router";
import { AppRoutingModule } from "./app.routing";
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// Libs
import { ToastrModule } from 'ngx-toastr';
import { IvyCarouselModule } from "angular-responsive-carousel";

// Angular material
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';

// General components
import { NavbarComponent } from "./shared/components/navbar/navbar.component";
import { FooterComponent } from "./shared/components/footer/footer.component";
import { AppComponent } from "./app.component";

// Shared components
import { TeamComponent } from "./pages/team/team.component";
import { TeamMemberComponent } from "./components/team-member/team-member.component";
import { ExercisesComponent } from "./pages/exercises/exercises.component";
import { ExerciseComponent } from "./components/exercise/exercise.component";
import { RegistrationComponent } from './components/registration/registration.component';
import { ExercisePreviewComponent } from "./components/exercise-preview/exercise-preview.component";

// Pages
import { LandingComponent } from "./pages/landing/landing.component";
import { RegistrationPageComponent } from './pages/registration-page/registration-page.component';
import { GSportPageComponent } from './pages/g-sport-page/g-sport-page.component';
import { PrivateTrainingsPageComponent } from './pages/private-trainings-page/private-trainings-page.component';
import { StaffPageComponent } from './pages/staff-page/staff-page.component';
import { ShopPageComponent } from './pages/shop-page/shop-page.component';
import { ExercisePageComponent } from "./pages/exercise-page/exercise-page.component";
import { TrainingRegistrationDialogComponent } from './shared/components/training-registration-dialog/training-registration-dialog.component';
import { PartnersPageComponent } from './pages/partners-page/partners-page.component';

// Providers
import { DatePipe, HashLocationStrategy, LocationStrategy } from "@angular/common";
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { ReturnPolicyComponent } from './pages/return-policy/return-policy.component';

@NgModule({
	declarations: [
		AppComponent,
		NavbarComponent,
		LandingComponent,
		FooterComponent,
		TeamMemberComponent,
		TeamComponent,
		ExercisesComponent,
		ExerciseComponent,
		ExercisePageComponent,
		ExercisePreviewComponent,
		RegistrationComponent,
		RegistrationPageComponent,
		GSportPageComponent,
		PrivateTrainingsPageComponent,
		StaffPageComponent,
		ShopPageComponent,
		TrainingRegistrationDialogComponent,
		PartnersPageComponent,
		TermsAndConditionsComponent,
		PrivacyComponent,
		ReturnPolicyComponent,
	],
	imports: [
		BrowserAnimationsModule,
		NgbModule,
		FormsModule,
		RouterModule,
		AppRoutingModule,
		IvyCarouselModule,
		ReactiveFormsModule,
		HttpClientModule,
		MatExpansionModule,
		MatDialogModule,
		ToastrModule.forRoot({
			timeOut: 3500,
			positionClass: 'toast-bottom-right',
			preventDuplicates: true,
		})
	],
	providers: [
		// {
		// 	provide: LocationStrategy, useClass: HashLocationStrategy
		// },
		// {
		// 	provide: LocationStrategy, useClass: PathLocationStrategy
		// },
		DatePipe
	],
	bootstrap: [AppComponent],
})
export class AppModule { }

import { DateTimeConstants } from './../../constants/dateTime.constants';
import { Component, OnInit } from '@angular/core';
import { RouteConstants } from './../../constants/route.constants';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  date: Date;
  dateTimeConstants = DateTimeConstants;
  routeConstants = RouteConstants;


  constructor() { }

  ngOnInit(): void {
    this.date = new Date();
  }

}

import { Component, Input, OnInit } from "@angular/core";
import { Exercise } from "../../shared/models/exercise";
import { Router } from "@angular/router";

@Component({
	selector: "app-exercise-preview",
	templateUrl: "./exercise-preview.component.html",
	styleUrls: ["./exercise-preview.component.scss"],
})
export class ExercisePreviewComponent implements OnInit {
	@Input() exercise: Exercise;

	constructor(private router: Router) {}

	ngOnInit(): void {
		console.log(this.exercise);
	}

	goToDetail(exerciseId: number) {
		console.log(exerciseId);
		this.router.navigateByUrl("/exercises/" + exerciseId);
	}
}

import { TeamMember } from './../../shared/models/team-member';
import { Component, OnInit } from '@angular/core';
import { Titles } from 'app/shared/enums/titles';

@Component({
  selector: 'app-staff-page',
  templateUrl: './staff-page.component.html',
  styleUrls: ['./staff-page.component.scss']
})
export class StaffPageComponent implements OnInit {

  teamMembers: TeamMember[] = [];

  constructor() {
  }

  ngOnInit(): void {
		this.initTeamMembers();
	}

	private initTeamMembers() {
		var olivier: TeamMember = {
			name: "Olivier Tas",
			title: Titles.HEAD_COACH,
			image: "assets/img/team/olivier2.jpg",
			fbLink: "https://www.facebook.com/olivier.tas2",
		};

		var erik: TeamMember = {
			name: "Erik Van Hoolst",
			title: Titles.IT_DEVELOPMENT,
			image: "assets/img/team/erik2.jpg",
			linkedInLink: "https://www.linkedin.com/in/erik-van-hoolst-96114a180/",
		};

		var robbe: TeamMember = {
			name: "Robbe Peeters",
			title: Titles.IT_DEVELOPMENT,
			image: "assets/img/default-avatar.png",
			linkedInLink: "https://www.linkedin.com/in/robbe-peeters-a23444a9/",
		};

		var steven: TeamMember = {
			name: "Steven Van Hoolst",
			title: Titles.VIDEOGRAPHER,
			image: "assets/img/team/steven2.jpg",
			linkedInLink: "https://www.linkedin.com/in/steven-van-hoolst-351064158/",
		};

		var jonna: TeamMember = {
			name: "Jonna Tshinkobo",
			title: Titles.DEVELOPMENT_COACH,
			image: "assets/img/team/jonna2.jpg",
			fbLink: "https://www.facebook.com/jonathan.davekaboking",
		};

		var kevin: TeamMember = {
			name: "Kevin Tshinkobo",
			title: Titles.DEVELOPMENT_COACH,
			image: "assets/img/team/kevin2.jpg",
			fbLink: "https://www.facebook.com/kevin.tshinkobo",
		};

		var free: TeamMember = {
			name: "Free De Smet",
			title: Titles.FINANCIAL_DEVELOPMENT,
			image: "assets/img/default-avatar.png",
			fbLink: "https://www.facebook.com/freeke.desmet",
		};

		var lawrence: TeamMember = {
			name: "Lawrence Bell",
			title: Titles.GRAPCHIC_DESIGNER,
			image: "assets/img/default-avatar.png",
			fbLink: "https://www.facebook.com/lawrence.bell.754",
		};

		var matteo: TeamMember = {
			name: "Matteo Van Staen",
			title: Titles.DEVELOPMENT_COACH,
			image: "assets/img/default-avatar.png",
			fbLink: "",
		};

		const members = [olivier, erik, robbe, steven, jonna, kevin, lawrence, free, matteo];
		this.teamMembers.push(...members);
	}

}

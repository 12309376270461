export class Exercise {
    id: number;
    title: string;
    type: ExerciseType;
    difficulty: ExerciseDifficulty;
    thumnailUrl: string;
    description: string;
}

export enum ExerciseType {
    DRIBBELING,
    SHOOTING,
    PASSING
}

export enum ExerciseDifficulty {
    BEGINNER,
    MEDIUM,
    ADVANCED,
    EXPERT
}
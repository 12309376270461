import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { Exercise, ExerciseDifficulty, ExerciseType } from "../models/exercise";

@Injectable({
	providedIn: "root",
})
export class ExercisesService {
	constructor() {}

	getAllExercises(): Observable<Exercise[]> {
		return of(exercices);
	}

	getExerciseById(id: number): Observable<Exercise> {
		return of(exercices.filter((x) => x.id === id).pop());
	}
}
var ex1: Exercise = {
	id: 1,
	title: "This is exercise 1",
	difficulty: ExerciseDifficulty.BEGINNER,
	type: ExerciseType.DRIBBELING,
	thumnailUrl: "assets/img/exercices/test-video.mp4",
	description:
		"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis viverra at lacus vitae faucibus. Nam vel lacus vulputate turpis vestibulum viverra at vel ante. Etiam laoreet consequat tellus. Etiam sed rutrum ipsum. Integer sollicitudin congue justo eu ornare.",
};
var ex2: Exercise = {
	id: 2,
	title: "This is exercise 2",
	difficulty: ExerciseDifficulty.EXPERT,
	type: ExerciseType.DRIBBELING,
	thumnailUrl: "assets/img/exercices/test-video.mp4",
	description:
		"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis viverra at lacus vitae faucibus. Nam vel lacus vulputate turpis vestibulum viverra at vel ante. Etiam laoreet consequat tellus. Etiam sed rutrum ipsum. Integer sollicitudin congue justo eu ornare.",
};
var ex3: Exercise = {
	id: 3,
	title: "This is exercise 3",
	difficulty: ExerciseDifficulty.ADVANCED,
	type: ExerciseType.PASSING,
	thumnailUrl: "assets/img/exercices/test-video.mp4",
	description:
		"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis viverra at lacus vitae faucibus. Nam vel lacus vulputate turpis vestibulum viverra at vel ante. Etiam laoreet consequat tellus. Etiam sed rutrum ipsum. Integer sollicitudin congue justo eu ornare.",
};
var ex4: Exercise = {
	id: 4,
	title: "This is exercise 4",
	difficulty: ExerciseDifficulty.BEGINNER,
	type: ExerciseType.SHOOTING,
	thumnailUrl: "assets/img/exercices/test-video.mp4",
	description:
		"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis viverra at lacus vitae faucibus. Nam vel lacus vulputate turpis vestibulum viverra at vel ante. Etiam laoreet consequat tellus. Etiam sed rutrum ipsum. Integer sollicitudin congue justo eu ornare.",
};
var ex5: Exercise = {
	id: 5,
	title: "This is exercise 5",
	difficulty: ExerciseDifficulty.BEGINNER,
	type: ExerciseType.SHOOTING,
	thumnailUrl: "assets/img/exercices/test-video.mp4",
	description:
		"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis viverra at lacus vitae faucibus. Nam vel lacus vulputate turpis vestibulum viverra at vel ante. Etiam laoreet consequat tellus. Etiam sed rutrum ipsum. Integer sollicitudin congue justo eu ornare.",
};
var ex6: Exercise = {
	id: 6,
	title: "This is exercise 6",
	difficulty: ExerciseDifficulty.BEGINNER,
	type: ExerciseType.SHOOTING,
	thumnailUrl: "assets/img/exercices/test-video.mp4",
	description:
		"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis viverra at lacus vitae faucibus. Nam vel lacus vulputate turpis vestibulum viverra at vel ante. Etiam laoreet consequat tellus. Etiam sed rutrum ipsum. Integer sollicitudin congue justo eu ornare.",
};

const exercices = [ex1, ex2, ex3, ex4, ex5, ex6];
// this.exercices.push(...exercices);

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { ApiUrl } from './apiurl';

@Injectable({
  providedIn: 'root'
})
export class RegistrationsService {

  constructor(private httpClient: HttpClient) { }

  sendEmail(subject: string, message: string, emailTo: string, emailFrom: string) {

    return this.httpClient.post(ApiUrl.ApiUrl + 'sendEmail.php', { 
      Subject: subject,
      Message: message,
      EmailTo: emailTo,
      EmailFrom: emailFrom
    });
  }
}
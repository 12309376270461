import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-shop-page',
  templateUrl: './shop-page.component.html',
  styleUrls: ['./shop-page.component.scss']
})
export class ShopPageComponent implements OnInit, OnChanges {

  constructor(
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) { }


  ngOnChanges(changes: SimpleChanges): void {
    this.initEcWidStore();
  }

  public ngOnInit() {
    this.initEcWidStore();
  }

  private initEcWidStore() {
    if (!localStorage.getItem('ecWidReladBugFix')) {
      localStorage.setItem('ecWidReladBugFix', 'no reload')
      location.reload()
    } else {
      localStorage.removeItem('ecWidReladBugFix')
    }

    let storeId = 67894033;

    let script = this._renderer2.createElement("script");
    script.setAttribute("type", "text/javascript");
    script.setAttribute("charset", "utf-8");
    script.setAttribute("data-cfasync", "false");


    script.setAttribute(
      "src",
      `https://app.ecwid.com/script.js?${storeId}&data_platform=code&data_date==2021-11-06`
    );
    script.onload = this.injectEcwidProductBrowser(storeId);

    this._renderer2.appendChild(this._document.body, script);
  }

  private injectEcwidProductBrowser(storeId) {
    return function () {
      const ecwidBrowserScript = document.createElement("script");
      ecwidBrowserScript.setAttribute("type", "text/javascript");
      ecwidBrowserScript.setAttribute("charset", "utf-8");
      ecwidBrowserScript.text = `xProductBrowser("categoriesPerRow=3","views=grid(20,3) list(60) table(60)","categoryView=grid","searchView=list","id=my-store-${storeId}");`;
      document.head.appendChild(ecwidBrowserScript);
    };
  }

  // public ngOnInit() {
  //   let storeId = 67894033;
  //   let script = this._renderer2.createElement("script");
  //   script.setAttribute("type", "text/javascript");
  //   script.setAttribute("charset", "utf-8");
  //   script.setAttribute("data-cfasync", "false");
  //   script.setAttribute(
  //     "src",
  //     `https://app.ecwid.com/script.js?${storeId}&data_platform=code&data_date=2020-02-17`
  //   );
  //   script.onload = this.injectEcwidProductBrowser(storeId);

  //   this._renderer2.appendChild(this._document.body, script);
  // }

  // private injectEcwidProductBrowser(storeId) {
  //   return function() {
  //     const ecwidBrowserScript = document.createElement("script");
  //     ecwidBrowserScript.setAttribute("type", "text/javascript");
  //     ecwidBrowserScript.setAttribute("charset", "utf-8");
  //     ecwidBrowserScript.text = `xProductBrowser("categoriesPerRow=3","views=grid(20,3) list(60) table(60)","categoryView=grid","searchView=list","id=my-store-${storeId}");`;
  //     document.head.appendChild(ecwidBrowserScript);
  //   };
  // }

}

import { Component, OnInit } from '@angular/core';
import { Titles } from 'app/shared/enums/titles';
import { TeamMember } from 'app/shared/models/team-member';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {
  teamMembers: Array<TeamMember> = [];

  constructor() { }

  ngOnInit(): void {
    this.initTeamMembers();
  }

  private initTeamMembers() {
    var olivier: TeamMember = {
      name: 'Olivier Tas',
      title: Titles.HEAD_COACH,
      image: 'assets/img/team/olivier.jpg',
      fbLink: 'https://www.facebook.com/olivier.tas2'
    };

    var erik: TeamMember = {
      name: 'Erik Van Hoolst',
      title: Titles.IT_DEVELOPMENT,
      image: 'assets/img/team/erik.jpg',
      linkedInLink: 'https://www.linkedin.com/in/erik-van-hoolst-96114a180/'
    };

    var robbe: TeamMember = {
      name: 'Robbe Peeters',
      title: Titles.IT_DEVELOPMENT,
      image: 'assets/img/team/robbe.jpg',
      linkedInLink: 'https://www.linkedin.com/in/robbe-peeters-a23444a9/'
    }

    const members = [olivier, erik,robbe];
    this.teamMembers.push(...members);
  }

}

import { RegistrationsService } from './../../shared/services/registrations.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  registrationForm: FormGroup;

  // Seperate focusses needed for icons in formfield
  focusFistname;
  focusLastname;
  focusAdres;
  focusEmail;
  focusPhone;
  focusMutualiteit;

  get isValidForm() {
    return this.registrationForm.valid;
  }

  get firstName() { return this.registrationForm.get('firstName'); }
  get lastName() { return this.registrationForm.get('lastName'); }
  get phoneNr() { return this.registrationForm.get('phoneNr'); }
  get email() { return this.registrationForm.get('email'); }
  get adress() { return this.registrationForm.get('adress'); }
  get mutualiteit() { return this.registrationForm.get('mutualiteit'); }

  constructor(private _formBuilder: FormBuilder, private _registrationsService: RegistrationsService, private _toastrService: ToastrService) { }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.registrationForm = this._formBuilder.group({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      phoneNr: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')]),
      adress: new FormControl('', [Validators.required]),
      mutualiteit: new FormControl('', [Validators.required]),
    });
  }

  sendRegistration() {
    if (this.isValidForm) {
      const firstNameVal = this.firstName.value;
      const lastNameVal = this.lastName.value;
      const phoneNr = this.phoneNr.value;
      const email = this.email.value;
      const adress = this.adress.value;
      const mutualiteit = this.mutualiteit.value;

      // this._registrationsService.sendEmail(firstNameVal, lastNameVal, phoneNr, email, adress, mutualiteit).subscribe(x => {
      //   console.log(x);
      //   this._toastrService.success('Uw inschrijving werd verstuurd.');
      // },
      //   () => {
      //     this._toastrService.error('Er is iets misgegaan');
      //   });
    }
  }

}

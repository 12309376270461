import { ReturnPolicyComponent } from './pages/return-policy/return-policy.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { StaffPageComponent } from './pages/staff-page/staff-page.component';
import { RouteConstants } from './shared/constants/route.constants';
import { PrivateTrainingsPageComponent } from './pages/private-trainings-page/private-trainings-page.component';
import { GSportPageComponent } from './pages/g-sport-page/g-sport-page.component';
import { ShopPageComponent } from './pages/shop-page/shop-page.component';
import { RegistrationPageComponent } from './pages/registration-page/registration-page.component';
import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { LandingComponent } from './pages/landing/landing.component';
import { TeamComponent } from './pages/team/team.component';
import { ExercisesComponent } from './pages/exercises/exercises.component';
import { ExercisePageComponent } from './pages/exercise-page/exercise-page.component';
import { PartnersPageComponent } from './pages/partners-page/partners-page.component';

const routes: Routes = [
    // TODO check used components, delete others
    // { path: 'team', component: TeamComponent },
    // { path: 'exercises', component: ExercisesComponent },
    // { path: 'exercises/:id', component: ExercisePageComponent },
    // { path: 'registration', component: RegistrationPageComponent },

    { path: RouteConstants.INDEX, component: LandingComponent },
    { path: RouteConstants.STAFF, component: StaffPageComponent },
    { path: RouteConstants.GSPORT, component: GSportPageComponent },
    { path: RouteConstants.PRIVATE_TRAINING, component: PrivateTrainingsPageComponent },
    { path: RouteConstants.SHOP, component: ShopPageComponent },
    { path: RouteConstants.PARTNERS, component: PartnersPageComponent },
    { path: RouteConstants.TERMS_AND_CONDITIONS, component: TermsAndConditionsComponent },
    { path: RouteConstants.PRIVACY, component: PrivacyComponent },
    { path: RouteConstants.RETURN_POLICY, component: ReturnPolicyComponent},
    { path: '', redirectTo: RouteConstants.INDEX, pathMatch: 'full' }
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes)
    ],
    exports: [
    ],
})
export class AppRoutingModule { }

import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Exercise, ExerciseDifficulty, ExerciseType } from 'app/shared/models/exercise';
import { ExercisesService } from 'app/shared/services/exercises.service';

@Component({
  selector: 'app-exercises',
  templateUrl: './exercises.component.html',
  styleUrls: ['./exercises.component.scss']
})
export class ExercisesComponent implements OnInit {
  exercices: Array<Exercise> = [];

  constructor(
    private _exercisesService: ExercisesService,
    private router: Router) { }

  ngOnInit(): void {
    this.initExercices();
  }

  initExercices() {
    // Dit is de lijst van oefeningen, deze gaan we later automatisch ophalen in de db. 
    this._exercisesService.getAllExercises().subscribe((exercises: Exercise[]) => {
      this.exercices = exercises;
    });
  }

  // Zo kan de filter werken, moet nog uitgebreid worden met meerdere opties etc
  private filterExample(filter: ExerciseDifficulty) {
    return this.exercices.filter(x => x.difficulty == filter);
  }

  goToDetail(exerciseId: number) {
    console.log(exerciseId);
    this.router.navigateByUrl('/exercises/' + exerciseId);
  }

}

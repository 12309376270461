export class RouteConstants {
    static INDEX = 'index';
    static GSPORT = 'gsport';
    static PRIVATE_TRAINING = 'prive-trainigen';
    static STAFF = 'staff';
    static SHOP = 'shop';
    static PARTNERS = 'partners';
    static CONTACT = 'contact';
    static TERMS_AND_CONDITIONS = 'terms-and-conditions';
    static PRIVACY = 'privacy';
    static RETURN_POLICY = 'return-policy';
}
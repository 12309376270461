import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { Exercise } from "app/shared/models/exercise";
import { ExercisesService } from "app/shared/services/exercises.service";

@Component({
	templateUrl: "./exercise-page.component.html",
	styleUrls: ["./exercise-page.component.scss"],
})
export class ExercisePageComponent implements OnInit {
	exercise: Exercise;
	exercises: Array<Exercise> = [];

	constructor(private route: ActivatedRoute, private _exercisesService: ExercisesService) {}

	ngOnInit(): void {
		this.route.params.subscribe((params) => {
			this._exercisesService.getExerciseById(Number.parseInt(params["id"], 2)).subscribe((exercise: Exercise) => {
				this.exercise = exercise;
			});
		});
		this.getOtherExercises();
	}

	getOtherExercises() {
		this._exercisesService.getAllExercises().subscribe((ex) => {
			this.exercises = ex;
		});
	}
}
